export function setAppVersion(version) {
  localStorage.setItem('appVersion', version);
}

export function getAppVersion() {
  return localStorage.getItem('appVersion') || null;
}

export function clearAppVersion() {
  localStorage.removeItem('appVersion');
}

export function setLegacyMessage(legacy) {
  localStorage.setItem('legacy', legacy ? 'y' : 'n');
}

export function getLegacyMessage() {
  return localStorage.getItem('legacy') || null;
}

export function clearLegacyMessage() {
  localStorage.removeItem('legacy');
}

export function clear() {
  clearAppVersion();
  clearLegacyMessage();
}

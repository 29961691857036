import decode from 'jwt-decode';
import {
  setToken,
  getToken,
  setCompany,
  getCompany,
  setUserAccounts,
  getUserAccounts,
  clearToken,
  clearUserAccounts,
} from '../localstorage/auth';

export const onSignIn = (jwt) => {
  setToken(jwt);
};

export const onCompany = (company) => {
  setCompany(company);
};

export const getCurrentCompany = () => {
  const company = getCompany();
  return company ? JSON.parse(company) : null;
};

export const onActiveUserChange = (user) => {
  let accounts = getUserAccounts();
  accounts = accounts ? JSON.parse(accounts) : null;
  if (accounts?.length) {
    for (let a = 0; a < accounts.length; a++) {
      accounts[a].active = false;
      if (accounts[a]._id === user._id) {
        accounts[a].active = true;
      }
    }
  }
  setUserAccounts(accounts);
};

export const onGetActiveUser = () => {
  let accounts = getUserAccounts();
  accounts = accounts ? JSON.parse(accounts) : null;
  if (accounts?.length) {
    for (let a = 0; a < accounts.length; a++) {
      if (accounts[a].active) {
        return accounts[a];
      }
    }
  }
  return null;
};

export const onSetUserAccounts = (accounts) => {
  setUserAccounts(accounts);
};

export const onGetUserAccounts = () => {
  const accounts = getUserAccounts();
  return accounts ? JSON.parse(accounts) : null;
};

export const getTokenUser = () => {
  let token = getJWTToken();
  if (!token || isTokenExpired(token)) {
    return null;
  }
  let decoded = token && decode(token);
  if (decoded && decoded.user) {
    let u = decoded.user;
    return u;
  }
  return null;
};

export const getUserForToken = (token) => {
  if (isTokenExpired(token)) {
    return null;
  }
  let decoded = token && decode(token);
  if (decoded && decoded.user) {
    let u = decoded.user;
    return u;
  }
  return null;
};

export const onSignOut = () => {
  clearToken();
  clearUserAccounts();
};

export const getJWTToken = () => {
  return getToken();
};

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }
  const date = new Date(0);
  date.setUTCSeconds(token.exp);
  return date;
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}

export const isSignedIn = () => {
  let token = getJWTToken();
  return !!token && !isTokenExpired(token);
};

export const requireAuth = (nextState, replace) => {
  if (!isSignedIn()) {
    replace({ pathname: '/signin' });
  }
};
